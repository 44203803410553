<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Search</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" style="padding:50px">
          <V-row>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.order_status"
                :items="orderStatus"
                clearable
                dense
                chips
                small-chips
                label="order Type"
                item-text="title"
                item-value="value"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.payment_status"
                :items="paymentStatus"
                clearable
                dense
                chips
                small-chips
                label="payment Status"
                item-text="title"
                item-value="value"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.country_name"
                :items="countries"
                clearable
                dense
                chips
                small-chips
                label="Countries"
                item-text="Name"
                item-value="Name"
                @change="loadData"
              >
              </v-autocomplete>
            </v-col> </V-row
        ></v-form>
      </v-card-text>
    </v-card>
    <V-row id="app">
      <v-col md="12">
        {{ trackingResults }}
      </v-col>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          @pageChanged="pageChanged"
          collection="orders"
          :items="orders"
          :itemHeader="ordersItemHeader"
          :length="length"
          @printInvoice="printInvoice"
          @createShipping="createShipping"
          @createFreeOrderShipping="createFreeOrderShipping"
          @getLastTrackingUpdate="getLastTrackingUpdate"
        />
      </v-col>
    </V-row>
    <div id="app2" v-if="invoiceData">
      <div class="title">
        <div class="subTitle">فاتورة شراء</div>
        <div class="subTitle">Invoice</div>
      </div>

      <V-row>
        <v-col md="12">
          <table class="pdfTable">
            <tr class="pdfTrTilte">
              <th
                class="pdfTd"
                colspan="3
            "
              >
                بيانات العميل Client Details
              </th>
            </tr>
            <tr>
              <td class="pdfTdTilte">Name</td>
              <td class="pdfTd">
                <!-- {{ invoiceData.customer_id.first_name }} -->
                <!-- {{ invoiceData.customer_id.last_name }} -->
                {{ invoiceData.address_id.full_name }}
              </td>
              <td class="pdfTdTilteRight">الاسم</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Phone Number</td>
              <td class="pdfTd">
                <!-- {{ invoiceData.customer_id.country_code
                }}{{ invoiceData.customer_id.phone }} -->
                {{ invoiceData.address_id.phone }}
              </td>
              <td class="pdfTdTilteRight">رقم الهاتف</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Address</td>
              <td class="pdfTd">
                {{ invoiceData.address_id.country_name }},{{
                  invoiceData.address_id.city
                }},{{ invoiceData.address_id.detail }}
              </td>
              <td class="pdfTdTilteRight">العنوان</td>
            </tr>
          </table>
        </v-col>
      </V-row>
      <V-row>
        <v-col md="12">
          <table class="pdfTable">
            <tr class="pdfTrTilte">
              <th
                class="pdfTd"
                colspan="3
            "
              >
                بيانات الطلب Order Details
              </th>
            </tr>
            <tr>
              <td class="pdfTdTilte">Number</td>
              <td class="pdfTd" style="font-weight: bold;">
                {{ invoiceData.id }}
              </td>
              <td class="pdfTdTilteRight">الرقم</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Date</td>
              <td class="pdfTd">{{ invoiceData.date }}</td>
              <td class="pdfTdTilteRight">التاريخ</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Payment</td>
              <td class="pdfTd">
                {{
                  invoiceData.payment_method
                    ? invoiceData.payment_method.method
                    : null
                }}
              </td>
              <td class="pdfTdTilteRight">الدفع</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Shipping</td>
              <td class="pdfTd">Shipping International Aramex</td>
              <td class="pdfTdTilteRight">التوصيل</td>
            </tr>
          </table>
        </v-col>
      </V-row>
      <V-row>
        <v-col md="12">
          <table class="pdfTable">
            <tr class="pdfTrTilte">
              <td class="pdfTd">SKU</td>
              <td class="pdfTd">Product المنتج</td>
              <td class="pdfTd">Qty العدد</td>
              <td class="pdfTd">
                <div>Price</div>
                <div>السعر</div>
              </td>
              <!-- <td class="pdfTd">
                <div>Discounted Price</div>
                <div>السعر بعد الخصم</div>
              </td> -->
              <td class="pdfTd">Total الإجمالي</td>
            </tr>
            <tr
              v-for="order_items in invoiceData.order_items"
              v-bind:key="order_items.id"
            >
              <td class="pdfTd2">{{ order_items.product_id.sku }}</td>
              <td class="pdfTd2">
                <div>{{ order_items.product_id.arabic_title }}</div>
                <div>{{ order_items.product_id.english_title }}</div>
              </td>
              <td class="pdfTd2">{{ order_items.quantity }}</td>
              <td class="pdfTd2">
                {{ order_items.price }}
                {{ order_items.currency_code }}
              </td>
              <!-- <td class="pdfTd2">
                {{ order_items.product_id.discounted_price
                }}{{ order_items.currency_code }}
              </td> -->
              <td class="pdfTd2">
                {{
                  parseFloat(order_items.price) *
                    parseFloat(order_items.quantity)
                }}
                {{ order_items.currency_code }}
              </td>
            </tr>
          </table>
        </v-col>
      </V-row>
      <V-row>
        <v-col md="12">
          <table class="pdfTable">
            <tr>
              <td class="pdfTdTilte">Subtotal</td>
              <td class="pdfTd">{{ invoiceData.subtotal }}</td>
              <td class="pdfTdTilteRight">الإجمالي</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Shipping Chargs</td>
              <td class="pdfTd">{{ invoiceData.shipping_value }}</td>
              <td class="pdfTdTilteRight">رسوم التوصيل</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Coupon Code</td>
              <td class="pdfTd">
                {{ invoiceData.coupon_id ? invoiceData.coupon_id.code : "" }}
              </td>
              <td class="pdfTdTilteRight">رمز الكوبون</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Coupon Discount</td>
              <td class="pdfTd">
                {{ invoiceData.coupon_value
                }}{{ invoiceData.is_persentage ? "%" : "" }}
              </td>
              <td class="pdfTdTilteRight">خصم الكوبون</td>
            </tr>
            <tr>
              <td class="pdfTdTilte">Grand Total</td>
              <td class="pdfTd" style="font-weight: bold;">
                {{ invoiceData.price }}
              </td>
              <td class="pdfTdTilteRight">الإجمالي الكلي</td>
            </tr>
          </table>
        </v-col>
      </V-row>
    </div>

    <button class="btn btn-danger" v-print="'#app2'">generate PDF</button>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";
import axios from "axios";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      length: 0,
      searchForm: {
        order_status: null,
        payment_status: null,
        country_name: null,
      },
      orderStatus: [
        { title: "Processing", value: "processing" },
        { title: "Shipped", value: "shipped" },
        { title: "Canceled", value: "canceled" },
        { title: "Complete", value: "complete" },
      ],
      paymentStatus: [
        { title: "Inprogress", value: "inprogress" },
        { title: "Success", value: "success" },
        { title: "COD", value: "cods" },
        { title: "Canceled", value: "canceled" },
        { title: "Error", value: "error" },
        { title: "Free", value: "free" },
      ],
      countries: [],
      orders: [],
      ordersItemHeader: [
        { text: "order", value: "id" },
        { text: "name", value: "name" },
        { text: "email", value: "customer_id.email" },
        { text: "phone", value: "customer_id.phone" },
        { text: "country", value: "address_id.country_name" },
        { text: "amount", value: "amount" },
        { text: "total", value: "price" },
        { text: "currency", value: "currency_code" },
        { text: "Total in KWD", value: "other_price" },
        { text: "shipment id", value: "shipment_id" },
        { text: "date", value: "date" },
        { text: "order status", value: "order_status" },
        { text: "payment status", value: "payment_status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      ordersItemHeader1: [
        { text: "order", value: "id" },
        { text: "name", value: "customer_id.first_name" },
        { text: "address", value: "address" },
      ],
      invoiceData: null,
      trackingResults: null,
    };
  },
  methods: {
    pageChanged(page) {
      console.log(page);
      this.page = page;
      this.loadData();
    },
    printInvoice(item) {
      this.invoiceData = item;
    },

    async getCountries() {
      const { data } = await ApiService.get(`custom/getAramexCountries`);
      if (!data.HasErrors) {
        console.log(data);
        this.countries = data.Countries;
        console.log(this.countries);
      } else {
      }
    },

    async createShipping(item) {
      const { data } = await ApiService.get(
        `custom/CreateShipments?orderId=${item.id}`
      );
      console.log(data);
      if (!data.HasErrors) {
        await ApiService.update(`items/orders`, item.id, {
          shipment_id: data.Shipments[0].ID,
          order_status: "shipped",
          shipment_label: data.Shipments[0].ShipmentLabel
            ? data.Shipments[0].ShipmentLabel.LabelURL
            : null,
        });
        this.loadData();
      } else {
      }
    },

    async getLastTrackingUpdate(shipment_id) {
      const { data } = await ApiService.get(
        `custom/getLastTrackingUpdate?orderId=${shipment_id}`
      );
      if (!data.HasErrors) {
        this.trackingResults =
          data.TrackingResults.length > 0
            ? data.TrackingResults[0].Value[0]
            : "no result";
      } else {
      }
    },

    async createFreeOrderShipping(item) {
      const { data } = await ApiService.get(
        `custom/CreateShipments?orderId=${item.id}`
      );
      console.log(data);
      if (!data.HasErrors) {
        await ApiService.update(`items/orders`, item.id, {
          shipment_id: data.Shipments[0].ID,
          order_status: "shipped",
          payment_status: "free",
          shipment_label: data.Shipments[0].ShipmentLabel
            ? data.Shipments[0].ShipmentLabel.LabelURL
            : null,
        });

        await ApiService.get(`custom/success?order_no=${item.id}`);
        this.loadData();
      } else {
        // console.log();
      }
    },

    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchData() {
      try {
        let url =
          "items/orders?fields=id,shipment_id,currency_code,amount,package_data,payment_method.method,customer_id.email,customer_id.country_code,order_items.product_id.discounted_price,customer_id.phone,ownerpushtoken,address_id.full_name,address_id.id,address_id.post_code,address_id.phone,address_id.country_name,address_id.city,address_id.detail,address_id.address_line_1,address_id.address_line_2,address_id.country_code,id,payment_status,order_status,customer_id.first_name,customer_id.last_name,amount,subtotal,shipping_methods.arabic_title,order_items.product_id.arabic_title,order_items.product_id.english_title,order_items.product_id.sku,order_items.quantity,order_items.price,order_items.other_price,order_items.id,date,order_items.currency_code,order_items.product_id.actual_price,price,shipping_value,subtotal,coupon_id.code,is_persentage,coupon_value,other_shipping_value,other_subtotal,other_price,shipment_label&sort=-id";
        url += `&meta=*&limit=10&page=${this.page}`;
        Object.keys(this.searchForm).forEach((element) => {
          if (this.searchForm[element]) {
            if (element == "country_name")
              url += `&filter[address_id.${element}]=${this.searchForm[element]}`;
            else url += `&filter[${element}]=${this.searchForm[element]}`;
          }
        });

        const data = await ApiService.get(url);
        console.log(data.data.data);
        console.log(data.data.meta.page_count);
        this.length = data.data.meta.page_count;
        data.data.data.forEach((element) => {
          element["address"] =
            element.address_id.full_name +
            "," +
            element.address_id.id +
            "," +
            element.address_id.post_code +
            "," +
            element.address_id.phone +
            "," +
            element.address_id.country_name +
            "," +
            element.address_id.city +
            "," +
            element.address_id.detail;
          element["name"] =
            element.customer_id.first_name +
            " " +
            element.customer_id.last_name;
          //             element.data = element.data.substring(
          //     str.indexOf(" ") + 1,
          //     str.lastIndexOf(":")
          // );

          // console.log(element.date);
          // const d = new Date(element.date);
          // d.prototype.addHours = function(h) {
          //   this.setTime(this.getTime() + h * 60 * 60 * 1000);
          //   return this;
          // };
          // element.date = d;
          let index = element.date.indexOf(" ") + 1;
          let index1 = element.date.indexOf(" ") + 2;
          let h = parseInt(element.date.charAt(index));
          let h1 = parseInt(element.date.charAt(index1));
          let date = element.date;
          if (h < 2) {
            if (h1 < 7) {
              element.date = this.replaceAt(date, h1 + 3, index1);
            } else {
              element.date = this.replaceAt(date, h1 - 7, index1);
              date = element.date;
              element.date = this.replaceAt(date, h + 1, index);
            }
          } else {
            if (h1 < 2) {
              element.date = this.replaceAt(date, h1 + 3, index1);
            } else {
              element.date = this.replaceAt(date, h1 - 1, index1);
              date = element.date;
              element.date = this.replaceAt(date, 0, index);
            }
          }
          // element.date = this.replaceAt(element.date, h+1,index);
        });
        this.orders = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    replaceAt(str, replacement, index) {
      console.log(str);
      return str.substr(0, index) + replacement + str.substr(index + 1);
    },
  },
  mounted() {
    this.fetchData(undefined);
    this.getCountries();
  },
};
</script>

<style>
.title {
  text-align: center;
  padding-bottom: 20px;
}
.subTitle {
  color: #01548e;
  font-size: 22px;
  font-weight: bold;
}
.pdfTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 30px;
  -webkit-print-color-adjust: exact;
}

.pdfTd {
  border: 1px solid #01548e;
  /* padding: 4px; */
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
  /* -webkit-print-color-adjust: exact; */
}

.pdfTd2 {
  border: 1px solid #01548e;
  /* padding: 4px; */
  font-size: 14px;
  /* font-weight: bold; */
  text-align: center;
  /* -webkit-print-color-adjust: exact; */
}

.pdfTrTilte {
  background-color: #01548e;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  /* -webkit-print-color-adjust: exact; */
}

.pdfTdTilte {
  background-color: #c8eef1;
  color: #01548e;
  font-size: 16px;
  font-weight: bold;
  /* text-align: center; */
  padding: 5px;
  border: 1px solid #01548e;
}

.pdfTdTilteRight {
  background-color: #c8eef1;
  color: #01548e;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  padding: 5px;
  border: 1px solid #01548e;
}
</style>
