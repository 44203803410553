<template>
  <v-container fluid>
    <v-data-table
      :headers="itemHeader"
      :items="items"
      :search="search"
      :loading="loading"
      class="elevation-1 "
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-7">
          <v-toolbar-title>Order Status</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- add / edit item component -->
          <!-- <ItemForm
            @closeDialog="openDialog = false"
            @formSubmitted="formSubmitted"
            :formTitle="formTitle"
            :dialog="openDialog"
            :record="record"
            :collection="collection"
            v-if="openDialog"
          />

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="
              () => {
                record = null;
                formTitle = 'Add New Item';
                openDialog = true;
              }
            "
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn> -->

          <!-- delete item -->
          <v-dialog v-model="dialogNotification" max-width="500px">
            <v-card>
              <v-card-title class="headline">send notification</v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        dir="rtl"
                        v-model="body"
                        :rules="textRules"
                        label="Notification Body"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="sendNotification()"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template
        v-slot:item.actions="{ item }"
        style="textAlign:right !important"
      >
        <v-icon color="primary" small class="mr-2" @click="printInvoice(item)">
          mdi-receipt
        </v-icon>
        <v-icon
          v-if="
            !item.shipment_id &&
              (item.payment_status == 'success' ||
                item.payment_status == 'cods')
          "
          color="primary"
          small
          class="mr-2"
          @click="createShipping(item)"
        >
          mdi-truck
        </v-icon>
        <v-icon
          v-if="item.shipment_id"
          color="primary"
          small
          class="mr-2"
          @click="getLastTrackingUpdate(item)"
        >
          mdi-update
        </v-icon>
        <v-icon
          v-if="item.shipment_label"
          color="primary"
          small
          class="mr-2"
          @click="openLable(item.shipment_label)"
        >
          mdi-printer
        </v-icon>
        <v-icon
          v-if="!item.shipment_id"
          color="primary"
          small
          class="mr-2"
          @click="createFreeOrderShipping(item)"
        >
          mdi-key
        </v-icon>
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-publish
        </v-icon>
        <v-icon
          color="primary"
          small
          class="mr-2"
          @click="sendNotificationModal(item)"
        >
          mdi-email
        </v-icon>

        <v-icon color="error" small class="mr-2" @click="cancelItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <template>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="length"
          circle
          @input="pageChange"
        ></v-pagination>
      </div>
    </template>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["items", "itemHeader", "collection", "length"],
  data() {
    return {
      page: 1,
      search: null,
      loading: false,
      openDialog: false,
      dialogNotification: false,
      formTitle: "Add New Item",
      record: null,
      body: null,
      textRules: [(v) => !!v || "field is required"],
      ownerpushtoken: null,
    };
  },

  methods: {
    pageChange() {
      console.log(this.page);
      this.$emit("pageChanged", this.page);
    },
    openLable(link) {
      window.open(link, "_blank");
    },
    async loadData() {
      try {
        this.$emit("loadData");
      } catch (error) {
        console.log(error);
      }
    },
    async printInvoice(item) {
      try {
        this.$emit("printInvoice", item);
      } catch (error) {
        console.log(error);
      }
    },
    async createShipping(item) {
      try {
        this.$emit("createShipping", item);
      } catch (error) {
        console.log(error);
      }
    },
    async getLastTrackingUpdate(item) {
      try {
        this.$emit("getLastTrackingUpdate", item.id);
      } catch (error) {
        console.log(error);
      }
    },

    async createFreeOrderShipping(item) {
      try {
        this.$emit("createFreeOrderShipping", item);
      } catch (error) {
        console.log(error);
      }
    },
    async editItem(item) {
      let status;
      if (item.order_status == "complete") status = "processing";
      else if (item.order_status == "processing") status = "shipped";
      else if (item.order_status == "shipped") status = "complete";
      console.log(status);

      const { data } = await ApiService.update(
        `items/${this.collection}`,
        item.id,
        { order_status: status }
      );
      this.loadData();
      // this.record = item;
      // this.formTitle = "Edit Record";
      // // this.openEditDialog = true;
      // this.openDialog = true;
    },
    async cancelItem(item) {
      const { data } = await ApiService.update(
        `items/${this.collection}`,
        item.id,
        { order_status: "canceled" }
      );
      this.loadData();
    },
    sendNotificationModal(record) {
      this.ownerpushtoken = record.ownerpushtoken;
      this.dialogNotification = true;
    },
    async sendNotification() {
      if (this.ownerpushtoken) {
        const { data } = await ApiService.get(
          `custom/sendNotification?InvoiceAmount=${this.ownerpushtoken}&body=${this.body}`
        );
        this.dialogNotification = false;
        //   let formData1 = new FormData();
        //   formData1.append("InvoiceAmount", this.ownerpushtoken);
        //   formData1.append("body", this.body);
        //   axios({
        //     method: "post",
        //     url: `https://diwanworld.app/notification.php`,
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //     data: formData1,
        //   }).then(
        //     (result) => {
        //       console.log(result);
        //     },
        //     (error) => {
        //       console.error(error);
        //     }
        //   );
        //   // const response = await axios.post(
        //   //   "https://exp.host/--/api/v2/push/send",
        //   //   {
        //   //     to: [item.ownerpushtoken],
        //   //     title: "title test",
        //   //     body: "Breaking news!",
        //   //   }
        //   // );
      }
    },
    // deleteItem(record) {
    //   this.record = record;
    //   this.dialogNotification = true;
    // },
    // async deleteItemConfirm() {
    //   try {
    //     this.loading = true;
    //     const { data } = await ApiService.delete(
    //       `items/${this.collection}/${this.record.id}`
    //     );
    //     this.loading = false;
    //     this.closeDelete();
    //     let msg;
    //     msg = "deleted successfully";
    //     alert(msg);
    //     this.loadData();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    closeDelete() {
      this.dialogNotification = false;
    },
    // formSubmitted(msg) {
    //   this.openDialog = false;
    //   alert(msg);
    //   this.loadData();
    // },
  },
  mounted() {
    // this.loadData();
  },
};
</script>

<style></style>
